<template>
  <div>
    <a-modal @cancel="closeModalSchedule" :title="`Schedule for ${classDetail.namaKelas || ''}`" centered :footer="null"
      :visible="scheduleIsVisible" :width="1200">
      <BoxScheduleAdmin :idTahunAjaran="idTahunAjaran" v-if="!loadingDataSchedule && modelSchedule"
        :modalVisible="scheduleIsVisible" :model="modelSchedule" :slots="allSlot" :viewOnly="true" type="classes" />
    </a-modal>
    <a-table :columns="columns" :data-source="classList" :loading="loading" :pagination="pagination" bordered>
      <span slot="total" slot-scope="val">{{ val }} Student(s)</span>
      <span slot="Actions" slot-scope="text, record">
        <a @click.prevent="seeSchedule(record)" class="btn btn-outline-primary mx-2">Schedule</a>
      </span>
    </a-table>
  </div>
</template>

<script>
const BoxScheduleAdmin = () => import('@/components/app/Admin/BoxScheduleAdmin')
const columns = [
  {
    title: 'Class',
    dataIndex: 'namaKelas',
    // width: '32%',
    align: 'center',
  },
  {
    title: 'Total Student',
    dataIndex: 'totalMurid',
    scopedSlots: { customRender: 'total' },
    align: 'center',
    // width: '32%',
  },
  {
    title: 'Actions',
    dataIndex: 'Actions',
    align: 'center',
    // width: '20%',
    scopedSlots: { customRender: 'Actions' },
  },
]

export default {
  props: ['idTahunAjaran'],
  components: {
    BoxScheduleAdmin,
  },
  data() {
    return {
      classList: [],
      pagination: {},
      loading: false,
      columns,
      modelSchedule: null,
      scheduleIsVisible: false,
      loadingDataSchedule: false,
      classDetail: {},
      allSlot: [],
    }
  },
  methods: {
    async fetchClass() {
      try {
        const data = await this.$store.dispatch('admin/FETCH_CLASS_SCHEDULE', { id_tahun_ajaran: Number(this.idTahunAjaran) })
        this.classList = data.map(dat => {
          return {
            ...dat,
            key: dat.id,
          }
        })
      } catch (err) {
        console.log(err)
      }
    },
    async seeSchedule(record) {
      try {
        if (!this.modelSchedule) {
          return this.$notification.error({
            message: 'Warning',
            description: 'You did not activate schedule setting yet, please activate it from Master Data.',
          })
        }
        this.loadingDataSchedule = true
        const scheduleList = await this.$store.dispatch('admin/FETCH_CLASS_SCHEDULE_BY_ID', { id_kelas: record.id })
        this.classDetail = record
        this.allSlot = scheduleList
        this.loadingDataSchedule = false
        this.scheduleIsVisible = true
      } catch (err) {
        this.$notification.error({
          message: 'Error',
          description: 'Error when fetching data.',
        })
        this.loadingDataSchedule = false
        this.scheduleIsVisible = false
        console.log(err)
      }
    },
    async fetchModelActiveSchedule() {
      try {
        const data = await this.$store.dispatch('slot/FETCH_MODEL_ACTIVE_SCHEDULE')
        this.modelSchedule = data
      } catch (err) {
        console.log(err)
      }
    },
    closeModalSchedule() {
      this.scheduleIsVisible = false
      this.classDetail = {}
      this.allSlot = []
    },
  },
  created() {
    this.fetchClass()
    this.fetchModelActiveSchedule()
  },
}
</script>

<style></style>
